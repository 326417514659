'use client';

import { cn } from '@ngg/storefront-utils';
import type {
  ExceptionalOpeningHoursDay,
  OpeningHoursDay,
  Store,
} from '@business/gql/graphql';
import {
  getIrregularOpeningHoursDisplayData,
  getOpeningHoursDisplayData,
} from '@utils/content/getOpeningHours';
import RichText from '../RichText/RichText';
import { HTMLAttributes } from 'react';
import useDictionary from '@utils/hooks/useDictionary';
import { Heading } from '../Heading/Heading';
import Paragraph from '../Paragraph/Paragraph';

type StoreProps = Store & {
  market: string;
  locale: string | undefined;
} & HTMLAttributes<HTMLDivElement>;

const Store = (props: StoreProps) => {
  const {
    title,
    className,
    address,
    postalCode,
    city,
    phoneNumber,
    email,
    description,
    openingHoursCollection,
    exceptionalOpeningHoursCollection,
    market,
    locale,
    googleMapsUrl,
  } = props;

  const dictionary = useDictionary({
    keys: [
      'closed',
      'contactUs',
      'email',
      'findHere',
      'phoneNumber',
      'address',
      'openingHours',
      'irregularOpeningHours',
    ],
  });

  const openingHoursData = openingHoursCollection?.items as OpeningHoursDay[];
  const irregularOpeningHours = exceptionalOpeningHoursCollection?.items as
    | ExceptionalOpeningHoursDay[]
    | undefined;
  const irregularDisplayData = irregularOpeningHours
    ? getIrregularOpeningHoursDisplayData(irregularOpeningHours, dictionary)
    : null;
  const displayData = getOpeningHoursDisplayData(
    openingHoursData,
    dictionary.closed,
  );

  return (
    <div className={cn('not-prose', className)}>
      <Heading as="h3">{title}</Heading>
      {description && (
        <RichText
          locale={locale}
          market={market}
          content={description}
          className="max-w-120 mb-6 ml-0 mt-3 space-y-3"
        />
      )}

      <div className="mb-10 mt-4">
        <Heading as="h4" className="mb-1 text-base font-bold">
          {dictionary?.contactUs}
        </Heading>
        <div className="row-gap-2 grid grid-cols-[100px_1fr] lg:gap-2">
          <Paragraph>{dictionary.email}:</Paragraph>
          <a
            href={`mailto:${email}`}
            className="whitespace-nowrap hover:underline">
            {email}
          </a>
          <Paragraph>{dictionary.phoneNumber}:</Paragraph>
          <Paragraph>{phoneNumber}</Paragraph>
        </div>
      </div>

      <div className="mt-3 grid grid-cols-1 gap-x-[clamp(1.5rem,_11%,_3.5rem)] gap-y-3 md:grid-cols-2">
        {displayData?.length ? (
          <div>
            <Heading as="h4" className="mb-1 text-base font-bold">
              {dictionary?.openingHours}
            </Heading>
            {displayData.map((item) => (
              <Paragraph
                key={item?.text}>{`${item?.text}: ${item?.time}`}</Paragraph>
            ))}
          </div>
        ) : null}

        {address || (postalCode && city) ? (
          <div>
            {address ? (
              <>
                <Heading as="h4" className="mb-1 text-base font-bold">
                  {dictionary?.address}
                </Heading>
                <Paragraph>{address}</Paragraph>
              </>
            ) : null}
            {postalCode && city ? (
              <Paragraph>
                {postalCode}, {city}
              </Paragraph>
            ) : null}
          </div>
        ) : null}

        {irregularDisplayData?.length ? (
          <div>
            <Heading as="h4" className="mb-1 text-base font-bold">
              {dictionary?.irregularOpeningHours}
            </Heading>
            {irregularDisplayData.map((item) => (
              <Paragraph
                key={item?.text}>{`${item?.text}: ${item?.time}`}</Paragraph>
            ))}
          </div>
        ) : null}

        {googleMapsUrl ? (
          <div className="self-end">
            <a
              target="_blank"
              rel="noreferrer nofollow"
              href={googleMapsUrl}
              className="font-bold underline">
              {dictionary.findHere}
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Store;
